@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;500;600;700&family=Share+Tech+Mono&family=Spectral:ital,wght@0,500;0,600;0,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,600;0,700;0,900;1,500;1,600&family=Merriweather:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Caveat+Brush|Schoolbell|Sue+Ellen+Francisco');
@font-face {
  font-family: 'hankrndbold';
  src: url('./assets/fonts/hankrnd-bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/hankrnd-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
* {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
*:focus {
  outline: none;
}
html, body { height: 100%; }
body { 
  margin: 0; 
  font-family: Roboto, "Helvetica Neue", sans-serif; }
$leftPading: 100px;
app-pro-section{
    width: 100%;
    display: flex;
    flex-direction: column;
}

@media (max-width:1000px) { 

  .logo-digital-digger, .logo-turn-off {
    padding: 35px 25px;

}
    .partial-padding{
    padding-top: 20px;
  }
app-pro-section{
  width: 100%;
  display: flex;
  flex-direction: column;
  // overflow-x: scroll;
    &::-webkit-scrollbar-track
        {
          //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          background-color: #F5F5F5;

        }

        &::-webkit-scrollbar
        {
          width: 10px;
          height:5px;
          background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb
        {
          background-color: #9c9c9c;
        }
        &::-webkit-scrollbar-thumb 
        {
          border-radius: 9px;
          width:10px;
          //background: linear-gradient(90deg,rgba(156,156,156,0) 24%, rgba(156,156,156,1) 25%, rgba(156,156,156,1) 75%, rgba(156,156,156,0) 76%);
      }
    }

}
